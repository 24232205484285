@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .grid-stack {
    @apply grid-cols-1 grid-rows-1;
  }

  .grid-stack > * {
    @apply col-start-1 row-start-1;
  }
}

/* 
Hides reCAPTCHA badge
https://developers.google.com/recaptcha/docs/faq#id-like-to-hide-the-recaptcha-badge.-what-is-allowed
*/
.grecaptcha-badge {
  visibility: hidden;
}

@font-face {
  font-family: "Comfortaa";
  src: url("./fonts/Comfortaa-Bold.ttf") format("truetype");
  font-weight: 700;
}

@font-face {
  font-family: "Comfortaa";
  src: url("./fonts/Comfortaa-Light.ttf") format("truetype");
  font-weight: 300;
}

@font-face {
  font-family: "Comfortaa";
  src: url("./fonts/Comfortaa-Regular.ttf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "Comfortaa";
  src: url("./fonts/Comfortaa-SemiBold.ttf") format("truetype");
  font-weight: 600;
}

@keyframes expand {
  from {
    height: 0%;
  }
  to {
    height: 100%;
  }
}

@keyframes appearFromRight {
  0% {
    transform: translateX(12rem);
    opacity: 0;
    animation-timing-function: ease-in;
  }
  50% {
    transform: translateX(6rem);
    opacity: 1;
    animation-timing-function: ease-out;
  }
  100% {
    transform: translateX(0rem);
    opacity: 1;
  }
}
